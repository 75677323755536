<template>
  <div class="contentbox">
    <div class="bankbox">
      <img src="../../assets/images/new/back-icon.png" alt="" @click="bankgo">
      <p class="bankname">分享奖励</p>
    </div>
    <div class="typebox">
      <div class="typeitem point" :class="{'actype':actypeindex===item.id}" @click="typechange(item.id)" v-for="item in searchList" :key="item.id">{{item.name}}</div>
    </div>
    <div class="center">
      <div class="infobox">
        <div class="infoitem">
          <div class="infotext">
            <p class="text">我的下级人数</p>
            <p class="numbox">{{ share_user_count }}</p>
          </div>
        </div>
        <div class="infoitem">
          <div class="infotext">
            <p class="text" v-if="this.user.anchor == 1">下级充值奖励 <span class="numbox">6%</span></p>
            <p class="text" v-else>下级充值奖励 <span class="numbox" style="vertical-align: middle">3%</span></p>
            <p class="numbox" style="display: flex; align-items: center;justify-content: center;"><img style="width: 0.12rem;margin-right: 0.1rem;" src="../../assets/images/about/user/huobi.png" alt="">{{ share_money }}</p>
          </div>
        </div>
      </div>
      <div class="title">推广链接</div>
      <div class="cord-box">
        <div class="info-box">
          <span class="span">{{ Url }}</span>
          <div @click="textCopy(Url)" class="btn point">复制</div>
        </div>
        <!-- <div class="btn btn-cord">二维码</div> -->
      </div>
      <div class="title">推广码</div>
      <div class="cord-box">
        <div class="info-box">
          <span class="span">{{ UrlCode }}</span>
          <div @click="textCopy(UrlCode)" class="btn point">复制</div>
        </div>
      </div>
      <div v-if="actypeindex==1">
        <div class="title">奖励说明</div>
        <div class="tipstext" v-if="this.user.anchor == 1">用户的下级通过充值T币时，用户可获得额外奖励。用户额外奖励 = 下级充值T币额度的6%（奖励即充即送！）</div>
        <div class="tipstext" v-else>用户的下级通过充值T币时，用户可获得额外奖励。用户额外奖励 = 下级充值T币额度的3%（奖励即充即送！）</div>
      </div>
      <div v-if="actypeindex==2">
        <div class="title">近期奖励</div>
        <newtable :tableRecordList="tableRecordList1" :tableData="tableData" @pageChange="RecentChange" />
      </div>
      <div v-if="actypeindex==3">
        <div class="title">下级详情</div>
        <newtable :tableRecordList="tableRecordList2" :tableData="downData" @pageChange="downChange" />
      </div>
    </div>
  </div>
</template>

<script>
import { tableRecordList1, tableRecordList2 } from "./index.js";
import { getawards_recordsApi, getlevel_detailApi } from "@/network/api.js";
import { mapState } from "vuex";
import newtable from '../../components/newtable/index.vue';
export default {
  data() {
    return {
      actypeindex: 1,
      tableRecordList1,
      tableRecordList2,
      tableData: {
        total: 0,
        pageSize: 0,
        list: [],
      },
      downData: {
        total: 0,
        pageSize: 0,
        list: [],
      },
    };
  },
  components: {
    newtable
  },
  computed: {
    ...mapState(["user"]),
    searchList() {
      let array = [
        {
          name: "奖励说明",
          id: 1,
        },
        {
          name: "近期奖励",
          id: 2,
        },
      ];
      if (this.user.anchor == 1) {
        array = [
          {
            name: "奖励说明",
            id: 1,
          },
          {
            name: "近期奖励",
            id: 2,
          },
          {
            name: "下级详情",
            id: 3,
          },
        ];
      }
      return array;
    },
    Url() {
      return `${location.origin}/Home?IvtCode=${this.user.invite_code}`;
    },
    UrlCode() {
      return `${this.user.invite_code}`;
    },
    share_money() {
      return `${this.user.promotion_total}`;
    },
    share_user_count() {
      return `${this.user.share_user_count}`;
    },
  },
  methods: {
    bankgo() {
      window.history.go(-1);
    },
    //下级分页
    downChange(v) {
      this.getdowndata(v.page);
    },
    getdowndata(num) {
      getlevel_detailApi(num).then((res) => {
        let data = res.data.data.data;
        if (data.length > 0) {
          this.downData = {
            total: res.data.data.total,
            pageSize: res.data.data.per_page,
            list: data.map(item => {
              return {
                name: item.name,
                total_recharge: item.total_recharge,
                total_record: item.total_record
              };

            })
          };
        }
      });
    },
    // 近期奖励分页
    RecentChange(v) {
      this.getdata(v.page);
    },
    // 近期奖励数据获取
    getdata(num) {
      getawards_recordsApi(num).then((res) => {
        let data = res.data.data.data;
        this.tableData = {
          total: res.data.data.total,
          pageSize: res.data.data.per_page,
          list: data.map((item) => {
            return {
              time: item.created_at,
              name: item.next_user.name,
              charge_bean: item.charge_bean,
              price: item.bean,
            };
          }),
        };
      });
    },
    typechange(v) {
      this.actypeindex = v;
      if (v == 2) {
        this.getdata(1);
      } else if (v == 3) {
        this.getdowndata(1);
      }
    },
    textCopy(t) {
      // 如果当前浏览器版本不兼容navigator.clipboard
      if (!navigator.clipboard) {
        var ele = document.createElement("input");
        ele.value = t;
        document.body.appendChild(ele);
        ele.select();
        document.execCommand("copy");
        document.body.removeChild(ele);

        if (document.execCommand("copy")) {
          this.$message.success("复制成功！");
        } else {
          this.$message.success("复制失败！");
        }
      } else {
        navigator.clipboard
          .writeText(t)
          .then(() => {
            this.$message.success("复制成功！");
          })
          .catch(() => {
            this.$message.success("复制失败！");
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.contentbox {
  width: 100%;
  position: relative;
  .bankbox {
    width: 3.35rem;
    margin: 0 auto;
    height: 0.44rem;
    display: flex;
    align-items: center;
    background: #020715;
    text-align: center;
    position: relative;
    img {
      position: absolute;
      width: 0.25rem;
      height: 0.14rem;
    }
    .bankname {
      width: 100%;
      text-align: center;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 0.16rem;
      color: #ffffff;
    }
  }
  .typebox {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 0.1rem;
    .typeitem {
      padding: 0.1rem;
      font-size: 0.12rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #636363;
    }
    .actype {
      color: #c5bbed;
    }
  }
  .center {
    width: 3.35rem;
    margin: 0 auto;
    .infobox {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.18rem;
      .infoitem {
        width: 1.63rem;
        height: 0.8rem;
        background: #101022;
        border: 0.01rem solid #201e43;
        border-radius: 0.06rem;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        .infotext {
          text-align: center;
          .text {
            margin-bottom: 0.2rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 0.12rem;
            color: #ffffff;
          }
        }
        .numbox {
          font-size: 0.12rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          color: #efa900;
        }
      }
    }
    .title {
      margin: 0.3rem 0 0.18rem 0;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 0.12rem;
      color: #ffffff;
    }
    .cord-box {
      width: 100%;
      .info-box {
        display: flex;
        .span {
          width: 2.67rem;
          height: 0.26rem;
          background: #08080f;
          border: 0.01rem solid #2f2f5e;
          line-height: 0.26rem;
          font-size: 0.1rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #8080b5;
          padding-left: 0.1rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .btn {
          width: 0.64rem;
          height: 0.26rem;
          background: linear-gradient(90deg, #242448 0%, #42426d 100%);
          border: 0.01rem solid #8686db;
          text-align: center;
          line-height: 0.26rem;
          font-size: 0.12rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          margin-left: 0.14rem;
        }
      }
    }
    .tipstext {
      font-size: 0.1rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #8080b5;
    }
  }
}
</style>
